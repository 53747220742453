section {
  padding-top: 112px;

  @media (max-width: 960px) {
    padding-top: 72px;
  }

  @media (max-width: 480px) {
    padding-top: 48px;
  }
}

.mainTitle {
  padding-top: 352px;
  padding-bottom: 136px;

  @media (max-width: 960px) {
    padding-top: 312px;
    padding-bottom: 96px;
  }

  @media (max-width: 480px) {
    padding-top: 256px;
    padding-bottom: 56px;
  }
}

.twoColumn {
  display: flex;
  justify-content: space-between;

  @media (max-width: 780px) {
    display: block;
  }

  .leftColumn,
  .rightColumn {
    width: calc(50% - 24px);

    @media (max-width: 780px) {
      width: 100%;
    }
  }

  .leftColumn + .rightColumn {
    @media (max-width: 780px) {
      margin-top: 40px;
    }
  }
}

.linkTo {
  text-align: center;
  padding-top: 56px;
  padding-bottom: 24px;

  @media (max-width: 960px) {
    padding-top: 40px;
    padding-bottom: 16px;
  }

  @media (max-width: 480px) {
    padding-top: 32px;
    padding-bottom: 16px;
  }

  a {
    font-size: 24px;
    text-decoration: underline;
    color: #000;

    @media (max-width: 960px) {
      font-size: 18px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.mainImage {
  .imageRatio {
    position: relative;
    width: 100%;

    &:after {
      padding-top: 56.25%;
      display: block;
      content: "";
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.competencies {
  .panelHeader {
    font-size: 30px;
    line-height: 1.5;

    @media (max-width: 960px) {
      font-size: 20px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
}

.workedWith {
  padding-top: 88px;

  @media (max-width: 960px) {
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (max-width: 480px) {
    padding-top: 40px;
    padding-left: 0;
    padding-right: 0;
  }

  .logos {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 24px;
    row-gap: 24px;

    @media (max-width: 780px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 16px;
      row-gap: 16px;
    }

    @media (max-width: 480px) {
      column-gap: 0;
      row-gap: 0;
    }

    .logo {
      position: relative;
      width: 100%;

      @media (max-width: 780px) {
        &:last-of-type {
          display: none;
        }
      }

      &:after {
        padding-top: 56.25%;
        display: block;
        content: "";
      }

      svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.workingWithMe {
  @media (max-width: 780px) {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media (max-width: 480px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .leftColumn {
    width: calc(70% - 24px);

    @media (max-width: 780px) {
      width: 100%;
    }
  }

  .rightColumn {
    width: calc(30% - 24px);

    @media (max-width: 780px) {
      display: flex;
      width: 100%;
    }

    @media (max-width: 480px) {
      justify-content: space-between;
    }
  }

  .largeText {
    font-size: 80px;
    font-weight: 800;

    @media (max-width: 480px) {
      font-size: 60px;
    }
  }

  .stats {

    @media (max-width: 480px) {
      width: 100%;
    }

    & + .stats {
      margin-top: 24px;

      @media (max-width: 780px) {
        margin-top: 0;
        margin-left: 40px;
      }

      @media (max-width: 48px) {
        margin-top: 0;
        margin-left: 24px;
      }
    }
  }
}

.experiences {
  .experience {
    display: flex;
    align-items: center;
    width: 100%;

    & + .experience {
      margin-top: 24px;
    }

    .twoColumn {
      display: flex;
      width: 100%;
    }

    .leftColumn,
    .rightColumn {
      display: flex;
      align-items: center;
      margin-top: 0;
    }

    .leftColumn {
      width: calc(40% - 24px);
    }

    .rightColumn {
      width: calc(60% - 24px);
    }

    p {
      text-align: right;
      width: 100%;
    }

    p,
    h3 {
      display: block;
    }
  }
}

.projects {
  .projectsContainer {
    .project {
      cursor: pointer;
      display: block;
      width: 100%;

      & + .project {
        margin-top: 80px;

        @media (max-width: 480px) {
          margin-top: 40px;
        }
      }

      &.tall {
        .projectImage {
          &:after {
            padding-top: 140%;
          }
        }
      }

      &.medium {
        .projectImage {
          &:after {
            padding-top: 80%;
          }
        }
      }

      .projectImage {
        position: relative;
        width: 100%;

        &:after {
          padding-top: 56.25%;
          display: block;
          content: "";
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      h3,
      p {
        color: #000;
      }

      h3 {
        margin-top: 24px;
      }

      h3 + p {
        margin-top: 8px;
      }
    }
  }
}

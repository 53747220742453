.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 40;
  padding: 40px 0;
  transform: translateY(0);
  transition: all 0.3s ease;
  background-color: #fff;

  &.shadow {
    box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13);
  }

  &.hidden {
    transform: translateY(-100%);
  }

  &.menuOpen {
    transform: translateY(0%);
    box-shadow: none;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftAlign {
      .logo {
        width: 33px;
        height: 58px;
      }
    }

    .desktopMenu {
      display: flex;

      @media (max-width: 780px) {
        display: none;
      }

      li {
        & + li {
          margin-left: 56px;

          @media (max-width: 480px) {
            margin-left: 32px;
          }
        }

        a {
          color: #000;
          font-size: 18px;
          font-weight: 400;

          @media (max-width: 960px) {
            font-size: 16px;
          }
        }
      }
    }

    .mobileMenu {
      display: none;

      @media (max-width: 780px) {
        display: block;
      }

      .hamburger {
        padding: 15px 15px;
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;

        &:hover {
          opacity: 0.7;
        }

        &.menuOpen {
          &:hover {
            opacity: 0.7;
          }

          .hamburgerInner {
            background-color: #000;
            transform: translate3d(0, -10px, 0) rotate(-45deg);
            transition-delay: 0.22s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &:before,
            &:after {
              background-color: #000;
            }

            &:after {
              top: 0 !important;
              opacity: 0;
              transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                opacity 0.1s 0.22s linear;
            }

            &:before {
              top: 0 !important;
              transform: rotate(-90deg);
              transition: top 0.1s 0.16s
                  cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
          }
        }

        .hamburgerBox {
          width: 40px;
          height: 24px;
          display: inline-block;
          position: relative;

          .hamburgerInner {
            display: block;
            top: 50%;
            margin-top: -2px;
            width: 40px;
            height: 4px;
            background-color: #000;
            border-radius: 4px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
            top: auto;
            bottom: 0;
            transition-duration: 0.13s;
            transition-delay: 0.13s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

            &:before,
            &:after {
              content: "";
              display: block;
              width: 40px;
              height: 4px;
              background-color: #000;
              border-radius: 4px;
              position: absolute;
              transition-property: transform;
              transition-duration: 0.15s;
              transition-timing-function: ease;
            }

            &:before {
              top: -10px;
              transition: top 0.12s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            &:after {
              bottom: -10px;
              top: -20px;
              transition: top 0.2s 0.2s
                  cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                opacity 0.1s linear;
            }
          }
        }
      }
    }
  }
}

.mobileNavigation {
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 39;
  display: none;
  padding-top: 200px;

  &.menuOpen {
    display: none;

    @media (max-width: 780px) {
      display: block;  
    }
  }

  ul {
    li {
      font-size: 60px;
      line-height: 1.5;

      a {
        color: #000;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

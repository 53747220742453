.ImageCarousel {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.container {
  width: 100%;
  margin: auto;
}

.slider {
  overflow: visible;
  width: 150vw;
  margin-left: -25vw;

  @media (max-width: 780px) {
    width: 200vw;
    margin-left: -50vw;
  }

  @media (max-width: 480px) {
    width: 280vw;
    margin-left: -90vw;
  }
}

.slide {
  overflow: visible;
  transition: opacity 0.15s ease-in-out;

  &.fullscreen {
    transition: none;
    transform: none !important;

    .inner {
      transition: none;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    transition: transform 0.15s ease-in-out;
    position: relative;
    cursor: zoom-in;

    img {
      width: 100%;
    }

    &.fullscreen {
      background-color: #080414 !important;
      object-fit: contain;
      position: fixed !important;
      top: 0px !important;
      right: 0px !important;
      bottom: 0px !important;
      left: 0px !important;
      box-sizing: border-box !important;
      min-width: 0px !important;
      max-width: none !important;
      min-height: 0px !important;
      max-height: none !important;
      width: 100% !important;
      height: 100% !important;
      transform: none !important;
      margin: 0px !important;
      padding: 40px !important;
      z-index: 9999;
      cursor: zoom-out;

      @media (max-width: 600px) {
        padding: 0 !important;
      }

      img {
        position: relative;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  &:not(.active) {
    .inner {
      transform: scale(0.8);

      @media (max-width: 480px) {
        transform: scale(0.9);
      }
    }
  }

  &.hidden {
    opacity: 0;
  }
}
